import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';

function Contact() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { name, email, subject, message } = data;

    console.log('Name: ', name);
    console.log('Email: ', email);
    console.log('Subject: ', subject);
    console.log('Message: ', message);

    try {
      const templateParams = {
        name,
        email,
        subject,
        message,
      };
      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_ID
      );
      reset();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="ContactForm ">
      <div className="container">
        {/* <div className="row "> */}
        <div className="col-12 text-center">
          <div className="contactForm ">
            <form
              id="contact-form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              {/* Row 1 of form */}
              <div className="row formRow  d-flex justify-content-center m-3">
                <div className="col-lg-6">
                  <input
                    type="text"
                    name="name"
                    {...register('name', {
                      required: {
                        value: true,
                        message: 'Please enter your name',
                      },
                      maxLength: {
                        value: 30,
                        message: 'Please use 30 characters or less',
                      },
                    })}
                    className="form-control formInput"
                    placeholder="Name"
                  ></input>
                  {errors.name && (
                    <span className="errorMessage">{errors.name.message}</span>
                  )}
                </div>
              </div>
              <div className="row  d-flex justify-content-center m-3">
                <div className="col-lg-6">
                  <input
                    type="email"
                    name="email"
                    {...register('email', {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    })}
                    className="form-control formInput"
                    placeholder="Email address"
                  ></input>
                  {errors.email && (
                    <span className="errorMessage">
                      Please enter a valid email address
                    </span>
                  )}
                </div>
              </div>
              {/* Row 2 of form */}
              <div className="row formRow  d-flex justify-content-center m-3">
                <div className="col-lg-6">
                  <input
                    type="text"
                    name="subject"
                    {...register('subject', {
                      required: {
                        value: true,
                        message: 'Please enter a subject',
                      },
                      maxLength: {
                        value: 75,
                        message: 'Subject cannot exceed 75 characters',
                      },
                    })}
                    className="form-control formInput"
                    placeholder="Subject"
                  ></input>
                  {errors.subject && (
                    <span className="errorMessage">
                      {errors.subject.message}
                    </span>
                  )}
                </div>
              </div>
              {/* Row 3 of form */}
              <div className="row formRow  d-flex justify-content-center m-3">
                <div className="col-lg-6">
                  <textarea
                    rows={4}
                    name="message"
                    {...register('message', {
                      required: true,
                    })}
                    className="form-control formInput"
                    placeholder="Message"
                  ></textarea>
                  {errors.message && (
                    <span className="errorMessage">Please enter a message</span>
                  )}
                </div>
              </div>
              <button className="btn btn-primary m-2 mb-3" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default Contact;
