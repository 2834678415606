import HeaderNavbar from './components/HeaderNavbar';
import Content from './components/Content';
import News from './pages/News';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import { Route, Routes } from 'react-router-dom';

import './App.css';

function App() {

  return (
    <div className="container">
      <HeaderNavbar />
      {/* <Content /> */}
      <Routes>
        <Route path="/" element={<Content />} />
        <Route path="/news" element={<News />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      {/* <Component /> */}
      <Footer />
    </div>
  );
}

export default App;
